import React from "react"
import onlinestore from "../../assets/images/banners/new-images/online-store.jpg"
import timeline from "../../assets/images/box-image/mitech-home-infotechno-box-large-image-03-480x298.webp"
import timelineblog from "../../assets/images/timeline/timeline-image-01.webp"
import imageauction from "../../assets/images/banners/new-images/auction.jpg"
import imageaggregators from "../../assets/images/banners/new-images/aggreagators.jpg"
import imagebooking from "../../assets/images/banners/new-images/booking.jpg"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

const EcommerceSolution = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img ecommerce"
          data-bg="assets/images/banners/new-banners/ecommerce.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Ecommerce Solutions</h1>
                  <h5 className="font-weight--normal text-white">
                    Aamanto helps retail companies, distributors, manufacturers,
                    and wholesalers increase customer satisfaction, increase
                    revenues, and grow their audiences by combining our
                    information technology and eCommerce development expertise.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--===========  our history wrapper  Start =============--> */}
        <div className="our-history-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- section-title-wrap Start --> */}
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our IT Solution</h6>
                  <h2 className="heading">Different Kinds<span className="text-color-primary"> of Ecommerce Solutions</span></h2>
                </div>
                {/* <!-- section-title-wrap Start --> */}
              </div>
            </div>

            <div className="timeline-area section-space--pt_60">
              <div className="row">
                <div className="col-lg-12">
                  <div className="ht-timeline style-01">
                    <ul className="tm-timeline-list ht-animation-queue">
                      <li className="line"></li>
                      <li className="item animate">
                        <div className="dots">
                          <div className="middle-dot"></div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 timeline-col left timeline-feature">
                            <div className="inner">
                              <div className="date-wrap">
                                <h4 className="year">01.</h4>
                              </div>

                              <div className="photo">
                                <img
                                  src={onlinestore}
                                  alt="timeline-image-01"
                                  className="img-fluid bright-50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 timeline-col right timeline-info">
                            <div className="inner">
                              <div className="content-wrap">
                                <div className="content-body">
                                  <h6 className="heading">Online Stores</h6>

                                  <div className="text">
                                    We provide device-agnostic web stores,
                                    coupons, and drop-shipping websites to
                                    ensure that your clients have a consistently
                                    great shopping experience no matter where
                                    they are. We manage online store projects of
                                    varying complexity and in various domains as
                                    an eCommerce development company, whether
                                    they are specialized in consumer or capital
                                    products, food delivery, media distribution,
                                    business, or public services.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="item animate">
                        <div className="dots">
                          <div className="middle-dot"></div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 timeline-col left timeline-feature">
                            <div className="inner">
                              <div className="date-wrap">
                                <h6 className="year"> 02. </h6>
                              </div>

                              <div className="photo">
                                <img
                                  src={
                                    "https://blog.me.com.br/wp-content/uploads/2018/02/marketplace-B2B.jpg"
                                  }
                                  alt="timeline-image-01"
                                  className="img-fluid bright-50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 timeline-col right timeline-info">
                            <div className="inner">
                              <div className="content-wrap">
                                <div className="content-body">
                                  <h6 className="heading">B2C Market Places</h6>

                                  <div className="text">
                                    We use multilingual and multi-currency
                                    marketplaces as the best option for a
                                    sporadic collaboration between different
                                    sellers and their customers. We help
                                    suppliers present and manage their offerings
                                    in an ideal manner and enable customers to
                                    rapidly access the most comprehensive
                                    collection of essential services and items
                                    through eCommerce web development.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="item animate">
                        <div className="dots">
                          <div className="middle-dot"></div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 timeline-col left timeline-feature">
                            <div className="inner">
                              <div className="date-wrap">
                                <h6 className="year"> 03. </h6>
                              </div>

                              <div className="photo">
                                <img
                                  src={
                                    "https://media.istockphoto.com/photos/shopping-online-concept-parcel-or-paper-cartons-with-a-shopping-cart-picture-id1249219777?k=20&m=1249219777&s=170667a&w=0&h=yXvnoE69Nowry1Pufqlcwz-p11pwflGou7p8DUmQruM="
                                  }
                                  alt="timeline-image-01"
                                  className="img-fluid bright-50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 timeline-col right timeline-info">
                            <div className="inner">
                              <div className="content-wrap">
                                <div className="content-body">
                                  <h6 className="heading">
                                    B2B Trade Portals
                                  </h6>

                                  <div className="text">
                                    We build feature-rich B2B portals that bring
                                    together all trading players in one place,
                                    such as suppliers, manufacturers,
                                    distributors, and their subsidiaries. Our
                                    products encourage companies to establish
                                    and manage secure multichannel global
                                    commerce, strengthen their alliances, launch
                                    competitive public offerings, and
                                    collaborate with traders worldwide
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="item animate">
                        <div className="dots">
                          <div className="middle-dot"></div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 timeline-col left timeline-feature">
                            <div className="inner">
                              <div className="date-wrap">
                                <h6 className="year"> 04. </h6>
                              </div>

                              <div className="photo">
                                <img
                                  src={imageauction}
                                  alt="timeline-image-01"
                                  className="img-fluid bright-50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 timeline-col right timeline-info">
                            <div className="inner">
                              <div className="content-wrap">
                                <div className="content-body">
                                  <h6 className="heading">
                                    Auction & Bidding Platforms
                                  </h6>

                                  <div className="text">
                                    We empower e-commerce firms to expand their
                                    trade experience through online auctions and
                                    bidding platforms. Owners of implemented
                                    systems can organize various types of
                                    auctions, configure bid items, use voice and
                                    video streaming during real-time auctions
                                    and bidding sessions, and provide end
                                    consumers with various payment choices.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="item animate">
                        <div className="dots">
                          <div className="middle-dot"></div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 timeline-col left timeline-feature">
                            <div className="inner">
                              <div className="date-wrap">
                                <h6 className="year"> 05. </h6>
                              </div>

                              <div className="photo">
                                <img
                                  src={
                                    "https://assets.entrepreneur.com/content/3x2/2000/1601931592-GettyImages-1211574810.jpg"
                                  }
                                  alt="timeline-image-01"
                                  className="img-fluid bright-50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 timeline-col right timeline-info">
                            <div className="inner">
                              <div className="content-wrap">
                                <div className="content-body">
                                  <h6 className="heading">
                                    Aggregators of Ecommerce
                                  </h6>

                                  <div className="text">
                                    If you're seeking a solution that will bring
                                    numerous vendors together and combine their
                                    offers under one roof, we're ready to help.
                                    We use eCommerce aggregators to make it
                                    easier for you to host and manage several
                                    businesses while also enabling you to
                                    promote your services and products.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="item animate">
                        <div className="dots">
                          <div className="middle-dot"></div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 timeline-col left timeline-feature">
                            <div className="inner">
                              <div className="date-wrap">
                                <h6 className="year"> 06. </h6>
                              </div>

                              <div className="photo">
                                <img
                                  src={imageaggregators}
                                  alt="timeline-image-01"
                                  className="img-fluid bright-50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 timeline-col right timeline-info">
                            <div className="inner">
                              <div className="content-wrap">
                                <div className="content-body">
                                  <h6 className="heading">
                                    Solutions for Booking & Testing
                                  </h6>

                                  <div className="text">
                                    We build and implement complex solutions as
                                    part of our eCommerce Development services
                                    to make booking and ticketing activities
                                    transparent and straightforward for
                                    customers worldwide. Payment security,
                                    solution performance under high loads, and
                                    seamless integration with third-party
                                    services are all areas where we focus.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--========= More Services Start ===========--> */}
        <div className="about-delivering-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">
                    Our Major Pillars of <span className="text-color-primary">Ecommerce Driven Business</span></h2>
                  <p>
                    Aamanto equips eCommerce firms with fully functional custom
                    systems and eCommerce modules to help them succeed in their
                    daily operations. We give you perpetual control over your
                    e-commerce processes by providing an integrated eCommerce
                    ecosystem.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Logistic Management</h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Warehouse Management </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        {" "}
                        Ecommerce Management of Documents{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        Payment Solutions and various Tools
                      </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Marketing and Sales Management</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">E-Commerce ERP </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        Product Information Management (PIM)
                      </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        Shipment and Inventory Management
                      </h5>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        Corporate Billing and Accounting
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--========= More Services End ===========--> */}
      </div>
    </div>
  )
}

export default EcommerceSolution
