import * as React from "react"
import Layout from "../components/layout"
import EcommerceSolution from "../components/Solutions/EcommerceSolution"






const ecommerce = () => {
  return (
  <Layout>
   <EcommerceSolution/>
  </Layout>
)
};
export default ecommerce;
